<template>
    <div class="page bg-secondary">
        <section class="wave wave--white pt-5 pb-3"
                 :class="{'wave--full': state === 'loading'}">
            <div class="wave__content w-100">
                <div class="login-logo ease-40"
                     :class="{'login-logo--loading': state === 'loading'}">
                    <div class="login-logo__img"
                         :style="{ backgroundImage: `url(${$appVersion('logoImage')})` }"></div>
                </div>
                <p class="login-title text-center h1">Réinitialisation du mot de passe</p>
            </div>
        </section>
        <section class="flex-grow-1 ease-20"
                 :class="{'pt-6': state !== 'loading'}">
            <b-container class="position-relative">
                <transition name="content-fade">
                    <b-row class="d-flex justify-content-center flex-wrap">
                        <b-col xl="6" lg="7" md="9" sm="11">
                            <b-form class="bg-white w-100 rounded px-3 pt-3 mb-6"
                                    @submit.prevent="updatePasswordAction">
                                <p class="mb-3">Veuillez entrer votre nouveau mot de passe :</p>
                                <div v-if="errors.length > 0" class="mb-3">
                                    <ul>
                                        <li v-for="(error, index) in errors" :key="`error-${index}`"
                                            class="text-danger">{{ error }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="activable-input">
                                    <b-form-input class="mb-3"
                                                  v-model="mdpForm.password"
                                                  placeholder="Nouveau mot de passe"
                                                  :type="showPw ? 'text' : 'password'"
                                                  required></b-form-input>
                                    <button class="activable-input__btn"
                                            @click.prevent="showPw = !showPw">
                                        <b-icon-eye v-show="!showPw"
                                                    class="activable-input__icon"></b-icon-eye>
                                        <b-icon-eye-slash v-show="showPw"
                                                          class="activable-input__icon"></b-icon-eye-slash>
                                    </button>
                                </div>
                                <div class="activable-input">
                                    <b-form-input class="activable-input__input"
                                                  v-model="mdpForm.password_confirmation"
                                                  placeholder="Confirmation de votre nouveau mot de passe"
                                                  :type="showPwConfirm ? 'text' : 'password'"
                                                  required></b-form-input>
                                    <button class="activable-input__btn"
                                            @click.prevent="showPwConfirm = !showPwConfirm">
                                        <b-icon-eye v-show="!showPwConfirm"
                                                    class="activable-input__icon"></b-icon-eye>
                                        <b-icon-eye-slash v-show="showPwConfirm"
                                                          class="activable-input__icon"></b-icon-eye-slash>
                                    </button>
                                </div>

                                <div class="h-0 d-flex align-items-center justify-content-center mt-5">
                                    <b-button size="lg"
                                              type="submit"
                                              :disabled="sending"
                                              variant="primary">
                                        <transition name="simple-fade">
                                            <b-spinner v-show="sending"
                                                       class="btn__spinner"></b-spinner>
                                        </transition>
                                        <span>Modifier</span>
                                    </b-button>
                                </div>
                            </b-form>
                        </b-col>
                    </b-row>
                </transition>
                <transition name="content-fade">
                    <b-row v-if="state === 'loading'"
                           class="d-flex justify-content-center flex-wrap">
                        <b-col class="text-white text-center"
                               xl="6" lg="7" md="9" sm="11">
                            <p class="display-1">{{ `${user.prenom} ${user.nom}` }}</p>
                            <p class="pt-4 pb-5">Vous allez être redirigé(e) vers<br>votre page d’accueil...</p>
                            <b-spinner></b-spinner>
                        </b-col>
                    </b-row>
                </transition>
            </b-container>
        </section>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {BIconEye, BIconEyeSlash, BIconX} from 'bootstrap-vue'
import AlertHandler from '@/alertHandler'
import authenticationService from '../../../services/authenticationService'

export default {
    name: 'PasswordReset',
    components: {BIconEye, BIconEyeSlash, BIconX},
    data() {
        return {
            showPw: false,
            showPwConfirm: false,
            state: 'login',
            mdpForm: {
                password: '',
                password_confirmation: '',
                token: this.$route.query.token
            },
            errors: [],
            sending: false,
            modal: null,
            text: {
                pwReset: 'Mot de passe oublié ?',
                firstConnexion: 'Première connexion ?'
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user
        })
    },
    methods: {
        updatePasswordAction() {
            this.sending = true
            authenticationService.resetPasswordToken(this.mdpForm).then((response) => {
                this.sending = false;
                if (response.data.etat === 'OK') {
                    this.$router.push('login');
                    AlertHandler.show({code: 'password-reset'});
                } else {
                    AlertHandler.show({message: response.data?.data?.[0]});
                }
            }).catch((error) => {
                this.sending = false
                this.mdpForm.password = ''
                this.mdpForm.password_confirmation = ''

                AlertHandler.show({code: 4005, error});
            })
        }
    }
}
</script>
